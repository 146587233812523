import debounce from 'lodash/debounce';

// eslint-disable-next-line import/no-cycle
import {
  getPositionForTtsFromCurrentScrollPosition,
  getRectsFromTtsPosition,
  scrollToTtsPosition,
} from '../../foreground/contentFramePortalGateInternalMethods';
import {
  populateTtsAbleElements,
  TextToSpeechContentFrameError,
} from '../../foreground/contentFramePortalGateInternalMethods/textToSpeechUtils';
import getClosestHTMLElement from '../../foreground/utils/getClosestHTMLElement';
import getNextElementWithinContainer from '../../foreground/utils/getNextNodeWithinContainer';
import getRangyClassApplier from '../../foreground/utils/getRangyClassApplier';
import isFocusableElement from '../../foreground/utils/isFocusableElement';
import {
  deserializeCanonicalPosition,
  serializePositionAsCanonical,
} from '../../foreground/utils/locationSerialization/chunked';
import type { LenientReadingPosition, TtsPosition, WordBoundary } from '../../types';
import makeLogger from '../../utils/makeLogger';
import { ScrollingManagerError } from './errors';
import type { MobileContentFrameWindow } from './types';

declare let window: MobileContentFrameWindow;

function preventDefault(e: TouchEvent) {
  e.preventDefault();
}

type ScrollListenerFunction = () => void;

const logger = makeLogger(__filename, { shouldLog: false });
export class ScrollingManager {
  // This is a base class for all things scrolling
  document = document;
  headerComponent: HTMLElement | undefined;
  headerContainer: HTMLElement | undefined;
  documentTextContent: HTMLElement | undefined;
  documentRoot: HTMLElement | undefined;
  documentRootContainer: HTMLElement | undefined;
  endOfContentElement: HTMLElement | undefined;
  headerContent: HTMLElement | undefined;
  headerImageContainer: HTMLElement | undefined;
  ttsPosIndicator: HTMLElement | undefined;
  ttsPosIndicatorEnd: HTMLElement | undefined;
  ttsAutoScrollingEnabled = false;
  ttsAbleElements: HTMLElement[] = [];
  highlightableElements: Element[] = [];
  readingPosition: LenientReadingPosition | null = null;
  wordBoundaries: WordBoundary[] = [];
  lastTTSWord: string | undefined;
  isScrollingDown = false;
  currentScrollValue = 0;
  previousScrollValue = 0;
  scrollTimer: ReturnType<typeof setTimeout> | undefined = undefined;
  scrollingEnabled = true;
  scrollEventsDisabledTimer: ReturnType<typeof setTimeout> | undefined = undefined;
  touchMoveThrottle = 0;
  scrollingEventsDisabled = true;
  updatingCenterElementDisabled = false;
  firstTimeOpenedDocumentOffset = 0;
  documentTextContentHeight = 0;

  scrollListeners: ScrollListenerFunction[] = [];

  initialized = false;
  currentCenteredElementInfo: { element?: HTMLElement | undefined | null; scrollDelta?: number; } = {};
  bodyObserver: ResizeObserver | undefined;

  window: MobileContentFrameWindow = window;
  initializeCallback: () => void = () => {
    throw new ScrollingManagerError('On initialize callback was never created');
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(window: MobileContentFrameWindow) {
    this.window = window;
  }

  getScrollingElement() {
    if (this.document.scrollingElement === null) {
      throw new ScrollingManagerError('ScrollingElement is null!');
    }
    return this.document.scrollingElement as HTMLElement;
  }

  getScrollingElementTop() {
    return this.getScrollingElement().scrollTop;
  }

  getAbsoluteScrollTopOfElement(element: HTMLElement) {
    return this.getScrollingElementTop() + element.getBoundingClientRect().top;
  }

  getReadingPositionScrollTop() {
    let readingPositionScrollTop = 0;
    let readingPositionElement;
    if (this.readingPosition?.serializedPosition) {
      readingPositionElement = this.getElementFromSerializedPosition(
        this.readingPosition?.serializedPosition,
      );
      if (!readingPositionElement) {
        return this.getScrollingElement().scrollHeight * (this.readingPosition?.scrollDepth ?? 0);
      }
      readingPositionScrollTop = this.getAbsoluteScrollTopOfElement(readingPositionElement);
    }
    return readingPositionScrollTop;
  }

  setScrollingElementTop(newTop: number) {
    this.getScrollingElement().scrollTop = newTop;
  }

  scrollingElementScrollTo({ top, behavior }: { top: number; behavior: 'smooth' | 'auto' | 'instant'; }) {
    this.getScrollingElement().scrollTo({ top, behavior });
  }

  async init(firstTimeOpenedDocumentOffset: number) {
    if (this.initialized) {
      throw new ScrollingManagerError(
        'ScrollingManager already initialized; make sure to not call init twice!',
      );
    }
    this.firstTimeOpenedDocumentOffset = firstTimeOpenedDocumentOffset;
    // Register functions for window so React Native can use them
    this.initializeHTMLComponents();
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('ScrollingManagerInit documentTextContentContainer not found!');
    }
    const newHeight = this.documentTextContent?.getBoundingClientRect().height;
    if (newHeight) {
      this.documentTextContentHeight = newHeight;
    }
  }

  createResizeObserver() {
    // this set timeout accounts for a brief moment where fonts load but don't apply correctly,
    // resulting in an unnecessary resize event
    this.initializeHTMLComponents();
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('ScrollingManagerInit documentTextContentContainer not found!');
    }
    if (this.bodyObserver) {
      return;
    }
    const debouncedOnResize = debounce(this.onResize.bind(this), 20);
    this.bodyObserver = new ResizeObserver(debouncedOnResize);
    this.bodyObserver.observe(this.documentTextContent);
  }

  destroyResizeObserver() {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('ScrollingManagerInit documentTextContentContainer not found!');
    }
    if (!this.bodyObserver) {
      return;
    }
    this.bodyObserver.unobserve(this.documentTextContent);
    delete this.bodyObserver;
  }

  onResize() {
    // This event is handled inside ResizeObserver which handles errors a bit annoyingly
    // eslint-disable-next-line no-alert
    alert('HandleResize must be implemented in child class');
  }

  addScrollListener(func: ScrollListenerFunction) {
    this.scrollListeners.push(func);
  }

  disableScrollEventsForNMilliseconds(milliseconds = 1000) {
    this.scrollingEventsDisabled = true;
    if (this.scrollEventsDisabledTimer) {
      clearTimeout(this.scrollEventsDisabledTimer);
      this.scrollEventsDisabledTimer = undefined;
    }
    if (milliseconds > 0) {
      this.scrollEventsDisabledTimer = setTimeout(() => {
        this.scrollingEventsDisabled = false;
      }, milliseconds);
    } else {
      this.scrollingEventsDisabled = false;
    }
  }

  updateCurrentCenteredElement() {
    throw new ScrollingManagerError('UpdateCurrentCenteredElement must be implemented in child class');
  }

  disableScrollEvents() {
    this.scrollingEventsDisabled = true;
  }

  enableScrollEvents() {
    if (this.scrollEventsDisabledTimer) {
      clearTimeout(this.scrollEventsDisabledTimer);
      this.scrollEventsDisabledTimer = undefined;
    }
    this.scrollingEventsDisabled = false;
  }

  scrollToElement(element: Element, offset = 0, behavior: 'auto' | 'smooth' = 'smooth') {
    throw new ScrollingManagerError('ScrollToElement must be implemented in child class');
  }

  scrollToRect(rect: DOMRect, offset = 0) {
    throw new ScrollingManagerError('ScrollToRect must be implemented in child class');
  }

  scrollToPercentOfViewport(percent: number, animated = false, disableEvents = false) {
    throw new ScrollingManagerError('ScrollToPercentOfViewport must be implemented in child class');
  }

  scrollToReadingPosition(readingPosition: LenientReadingPosition) {
    throw new ScrollingManagerError('ScrollToReadingPosition must be implemented in child class');
  }

  scrollToSerializedPosition(serializedPosition: string, offset: number) {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError(
        'ScrollToSerializedPosition no document text content container found',
      );
    }

    const target = this.getElementFromSerializedPosition(serializedPosition);

    if (!target) {
      throw new ScrollingManagerError(
        `ScrollToSerializedPosition no target found for serialized position ${serializedPosition}`,
      );
    }

    this.currentCenteredElementInfo = { element: target, scrollDelta: offset };
    this.scrollToElement(target, -offset, 'auto');
  }

  initializeHTMLComponents() {
    if (!this.headerContent) {
      const headerContentResult = this.document.querySelector<HTMLElement>('.header-content');
      if (!headerContentResult) {
        throw new ScrollingManagerError('No .header-content found');
      }
      this.headerContent = headerContentResult;
    }
    const headerImageContainerResult = this.document.getElementById('header-image-container');
    if (!headerImageContainerResult) {
      throw new ScrollingManagerError('No #header-image-container found');
    }
    this.headerImageContainer = headerImageContainerResult;
    if (!this.headerComponent) {
      const headerComponentResult = this.document.getElementById('document-header');
      if (!headerComponentResult) {
        throw new ScrollingManagerError('No #header found');
      }
      this.headerComponent = headerComponentResult;
    }
    if (!this.headerContainer) {
      const headerContainerResult = this.document.querySelector<HTMLElement>('.header-container');
      if (!headerContainerResult) {
        throw new ScrollingManagerError('No .header-container found');
      }
      this.headerContainer = headerContainerResult;
    }
    if (!this.documentTextContent) {
      const documentContentResult = this.document.getElementById('document-text-content');
      if (!documentContentResult) {
        throw new ScrollingManagerError('No #document-text-content found');
      }
      this.documentTextContent = documentContentResult;
    }
    if (!this.documentRoot) {
      const documentRootResult = this.document.querySelector<HTMLElement>('.document-root');
      if (!documentRootResult) {
        throw new ScrollingManagerError('No .document-root found');
      }
      this.documentRoot = documentRootResult;
    }
    if (!this.documentRootContainer) {
      const documentRootContainerResult =
        this.document.querySelector<HTMLElement>('.document-container');
      if (!documentRootContainerResult) {
        throw new ScrollingManagerError('No .document-container found');
      }
      this.documentRootContainer = documentRootContainerResult;
    }
    if (!this.ttsPosIndicator) {
      this.ttsPosIndicator = this.document.createElement('div');
      this.ttsPosIndicator.classList.add('tts-position-indicator', 'tts-position-indicator-start');
      this.document.body.appendChild(this.ttsPosIndicator);
    }
    if (!this.ttsPosIndicatorEnd) {
      this.ttsPosIndicatorEnd = this.document.createElement('div');
      this.ttsPosIndicatorEnd.classList.add('tts-position-indicator', 'tts-position-indicator-end');
      this.document.body.appendChild(this.ttsPosIndicatorEnd);
    }

    const endOfContentElementResult = this.document.querySelector<HTMLElement>('#end-of-content');
    if (!endOfContentElementResult) {
      throw new ScrollingManagerError('computePageRects: No end of content element found');
    }
    this.endOfContentElement = endOfContentElementResult;
  }

  handleScrollFromHref() {}

  refreshPageSnapshotsForCurrentPage() {
    // Only implemented in paginated scrolling manager
  }

  setReadingPosition(pos: LenientReadingPosition | null) {
    this.readingPosition = pos;
  }

  updateWordBoundaries(wordBoundaries: WordBoundary[]) {
    this.wordBoundaries = wordBoundaries;
  }

  toggleTTSAutoScrolling(enabled: boolean) {
    this.ttsAutoScrollingEnabled = enabled;
  }

  isDocumentScrolledToBeginning(): boolean {
    throw new ScrollingManagerError('IsDocumentScrolledToBeginning must be implemented in child class');
  }

  getContentHeight(): number | undefined {
    throw new ScrollingManagerError('GetContentHeight must be implemented in child class');
  }

  getElementFromSerializedPosition(serializedPosition: string): HTMLElement | undefined {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError(
        'ScrollToSerializedPosition no document text content container found',
      );
    }

    try {
      const position = deserializeCanonicalPosition({
        classApplier: getRangyClassApplier(),
        rootNode: this.documentTextContent,
        serialized: serializedPosition,
      });

      const range = this.document.createRange();
      range.setStart(position.node, position.offset);
      range.setEnd(position.node, position.offset);
      const closestElement = getClosestHTMLElement(position.node);
      if (!closestElement) {
        throw new ScrollingManagerError('Could not get closest element from node');
      }

      return isFocusableElement(closestElement)
        ? closestElement
        : (getNextElementWithinContainer({
          container: this.documentTextContent,
          direction: 'next',
          element: closestElement,
          matcher: isFocusableElement,
        }) as HTMLElement);
    } catch (e) {
      logger.error('Error getting element from serialized position', { e });
    }
  }

  async playTtsFromCurrentScrollPosition() {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('Document element not found');
    }
    this.ttsAutoScrollingEnabled = false;

    const positionResult = await getPositionForTtsFromCurrentScrollPosition({
      contentContainer: this.documentTextContent,
      getIsDocumentScrolledToTop: () => this.isDocumentScrolledToBeginning(),
      ttsAbleElements: this.ttsAbleElements,
      window: this.window,
    });
    if (!positionResult) {
      return;
    }

    this.ttsAutoScrollingEnabled = true;
    const { elementIndex, position } = positionResult;

    if (typeof elementIndex !== 'undefined') {
      this.window.portalGateToForeground.emit('play-tts-from-element', { elementIndex, position });
      return;
    }
    this.window.portalGateToForeground.emit('play-tts-from-timestamp', { timestamp: position });
  }

  scrollViewportToCurrentTTSLocation(rect: DOMRect) {
    throw new ScrollingManagerError(
      'ScrollViewportToCurrentTTSLocation must be implemented in child class',
    );
  }

  getScrollDepthForTtsPosition(ttsPosition: TtsPosition): number | undefined {
    const scrollableRoot = this.getScrollingElement();
    const contentContainer = this.documentTextContent;
    if (!contentContainer || !scrollableRoot) {
      return;
    }
    const rect = getRectsFromTtsPosition({
      contentContainer,
      scrollableRoot,
      ttsPosition,
      returnRepeatedValue: true,
    })?.rect;
    if (!rect) {
      return;
    }
    return (rect.top + 350) / (scrollableRoot.scrollHeight - scrollableRoot.clientHeight);
  }

  async scrollToTtsPosition(ttsPosition: TtsPosition, skipIndicatorUpdate: boolean) {
    const scrollableRoot = this.getScrollingElement();
    const contentContainer = this.documentTextContent;
    if (!contentContainer || !scrollableRoot) {
      return false;
    }

    // If TTS is not on, neither is auto-scrolling.
    // If that's the case, we want to briefly re-enable it to get to the position.
    const isScrollingManagerAutoScrollEnabled = this.ttsAutoScrollingEnabled;
    this.ttsAutoScrollingEnabled = true;
    await scrollToTtsPosition({
      contentContainer,
      scrollableRoot,
      ttsPosition,
      isAutoScrollEnabled: true,
      skipIndicatorUpdate,
      useRepeatedLastWord: true,
    });
    this.ttsAutoScrollingEnabled = isScrollingManagerAutoScrollEnabled;

    return true;
  }

  computeSerializedPositionFromCenteredElement() {
    const { element, scrollDelta } = this.currentCenteredElementInfo;
    if (!this.documentTextContent || !element || scrollDelta === undefined) {
      return;
    }
    const serializedPosition = serializePositionAsCanonical({
      classApplier: getRangyClassApplier(),
      node: element,
      offset: 0,
      rootNode: this.documentTextContent,
    });

    return {
      serializedPosition,
      serializedPositionElementOffset: scrollDelta,
    };
  }

  createHighlightAtTtsPosition(ttsPos: TtsPosition) {
    if (!this.documentTextContent) {
      throw new ScrollingManagerError('Document element not found');
    }
    const { paraIndex } = ttsPos;

    if (!this.ttsAbleElements.length) {
      populateTtsAbleElements(this.documentTextContent, this.ttsAbleElements);
    }
    const node = this.ttsAbleElements[paraIndex];

    if (!node.textContent) {
      throw new TextToSpeechContentFrameError('Could not find text node');
    }

    const highlightRange = new Range();
    highlightRange.selectNode(node);

    const selection = this.document.getSelection();
    if (!selection) {
      throw new TextToSpeechContentFrameError('no selection');
    }
    selection.removeAllRanges();
    selection.addRange(highlightRange);

    this.window.portalGateToForeground.emit('create-highlight');
  }

  scrollToTop() {
    this.scrollToPercentOfViewport(0, true);
  }

  returnToReadingPosition() {
    throw new ScrollingManagerError('ReturnToReadingPosition must be implemented in child class');
  }

  onScrollStart() {
    throw new ScrollingManagerError('OnScrollStart must be implemented in child class');
  }

  // This is unthrottled, only add code to this if you need to listen to scroll a lot (like animating elements due to scroll position)
  onScroll() {
    throw new ScrollingManagerError('OnScroll must be implemented in child class');
  }

  onScrollEnd() {
    throw new ScrollingManagerError('OnScrollEnd must be implemented in child class');
  }

  onTouchMove(e: TouchEvent) {
    throw new ScrollingManagerError('OnTouchMove must be implemented in child class');
  }

  toggleScrollingEnabled(enabled: boolean) {
    if (!enabled) {
      if (this.scrollingEnabled) {
        this.document.body.style.overflow = 'hidden';
        this.window.addEventListener('touchmove', preventDefault, false); // mobile
      }
      this.scrollingEnabled = false;
    } else {
      this.window.removeEventListener('touchmove', preventDefault, false);
      this.document.body.style.overflow = 'visible';
      this.scrollingEnabled = true;
    }
  }
}
